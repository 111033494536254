












import { Component, Prop, Vue } from 'vue-property-decorator'
import { fetchMailingListPage } from '@elitepage/api/mailing-list-page'
import { MailingListPageStore } from '@elitepage/store/mailing-list-page'

@Component
export default class MailingListThankYouPage extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    @MailingListPageStore.State('mailingListPage') mailingListPage: IElitepage

    @MailingListPageStore.Action('loadMailingListPage') loadMailingListPage: (producerSlug: IProducer['slug']) => void

    async mounted() {
        await this.loadMailingListPage(this.producerSlug)
    }
}
